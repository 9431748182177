<!-- #################################################################################### -->
<!-- ###### HERINCOHAN                                                             ###### -->
<!-- ###### @author: Estefania Villada Chavarria                                   ###### -->
<!-- ###### @date: Julio 2024                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <div class="contenido">
    <section class="d-flex">
      <!-- Filtrar por nombre -->
      <v-text-field v-model="buscar.nombre" label="Nombre" outlined dense hide-details
        @input="listarGrupoProceso()" color="primary">
      </v-text-field>
      <!-- Este botón abre un diálogo para agregar una nueva aseguradora -->
      <div v-if="enterprise.code !== 1" class="ms-2">
        <v-tooltip left color="success" :open-on-focus="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn small fab color="success" v-on="on" v-bind="attrs"
              @mousedown.prevent="dialogoGrupoProceso = true">
              <v-icon>add</v-icon>
            </v-btn>
          </template>
          <span>Agregar</span>
        </v-tooltip>
      </div>
    </section>

    <v-data-table class="elevation mt-4" :items="tablaGrupoProceso.items" :loading="tablaGrupoProceso.loading"
      fixed-header :headers="tablaGrupoProceso.headers" :footer-props="tablaGrupoProceso.footerProps"
      @update:items-per-page="(i) => tablaGrupoProceso.itemsPerPage = i" @update:page="(p) => tablaGrupoProceso.page = p" 
      :items-per-page="tablaGrupoProceso.itemsPerPage" :page="tablaGrupoProceso.page"
      show-current-page :server-items-length="tablaGrupoProceso.totalElements" height="64vh">
      <template v-slot:[`item.eliminado`]="{ item }">
        <v-icon :color="!item.eliminado ? 'success' : 'error'">
          {{ !item.eliminado ? 'check_circle' : 'cancel' }}
        </v-icon>
        {{ !item.eliminado ? 'Activo' : 'Inactivo' }}
      </template>

      <template v-slot:[`item.acciones`]="{ item }">
        <v-tooltip bottom :color="!item.eliminado ? 'orange' : 'grey'">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon fab small v-on="on" v-bind="attrs" :color="!item.eliminado ? 'orange' : 'grey'">
              <v-icon @click="!item.eliminado && abrirDialogoEditar(item)">border_color</v-icon>
            </v-btn>
          </template>
          <span>{{ !item.eliminado ? 'Editar' : 'Grupo inactivo' }}</span>
        </v-tooltip>

        <v-tooltip  v-if="enterprise.code !== 1" bottom :color="!item.eliminado ? 'error' : 'success'">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon fab small v-on="on" v-bind="attrs" :color="!item.eliminado ? 'error' : 'success'">
              <v-icon @click="abrirDialogoEstado(item)">{{ !item.eliminado ? 'person_add_disabled' : 'how_to_reg' }}</v-icon>
            </v-btn>
          </template>
          <span>{{ !item.eliminado ? 'Inactivar' : 'Activar' }}</span>
        </v-tooltip>
      </template>

      <template v-slot:[`footer.page-text`]="items">
        {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
      </template>
    </v-data-table>

    <v-dialog v-model="dialogoGrupoProceso" persistent transition="dialog-bottom-transition" max-width="30rem" :editar="false">
      <v-form ref="formGrupoProceso">
        <v-card>
          <v-card-title class="encabezado">{{ tituloDialogo }}</v-card-title>
          <v-card-text class="pt-6">
            <v-text-field v-model="crearGrupoProceso.nombre" dense outlined label="Nombre"
              :rules="[rules.required]" color="primary">
            </v-text-field>
            <v-autocomplete v-model="crearGrupoProceso.proceso" dense outlined label="Proceso" :items="listadoProcesosActivos"
              :rules="[rules.required]" color="primary" item-value="id" item-text="name" :menu-props="{ maxHeight: 200 }" @update:search="searchProcesosActivos">
            </v-autocomplete>
            <div class="d-flex justify-end">
              <v-btn class="me-2" text color="error" @click="clear()">Cerrar</v-btn>
              <v-btn :color="!formValid ? 'grey' : 'success'" :disabled="!formValid" depressed
                @click="!editar ? agregarGrupoProceso() : editarGrupoProceso()">
                Guardar
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-form>
    </v-dialog>

    <!-- dialogo para cambiar el estado -->
    <v-dialog v-model="dialogoCambiarEstado" transition="dialog-bottom-transition" max-width="23rem" persistent>
      <v-card>
        <v-card-title class="encabezado">
          <span class="text-h6">
            {{ mensajeEstado }}
          </span>
        </v-card-title>
        <v-card-text class="dialogo">
          <div class="d-flex justify-end mt-5">
            <v-btn class="me-2" color="error" text @click="dialogoCambiarEstado = false">No</v-btn>
            <v-btn color="success" depressed text @click="cambiarEstado()">Si</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>
<!-- #################################################################################### -->
<!-- ###### Sección de Script                                                      ###### -->
<!-- #################################################################################### -->
<script>
import { mapState } from "vuex";
import { Role } from "../../../../router/role";

export default {
  name: "GrupoProceso",
  props: {
  },
  components: {
  },
  data() {
    return {
      buscar: {
        nombre: ''
      },
      crearGrupoProceso: {
        nombre: '',
        proceso: '',
      },
      dialogoGrupoProceso: false,
      tablaGrupoProceso: {
        items: [],
        loading: false,
        headers: [
          { text: "CÓDIGO STONE", value: 'codStone', align: "center", sortable: false },
          { text: "NOMBRE", value: 'nombre', align: "left", sortable: false },
          { text: "PROCESO", value: 'proceso.nombre', align: "left", sortable: false },
          { text: "ESTADO", value: 'eliminado', align: "left", sortable: false },
          { text: "ACCIONES", value: 'acciones', align: "center", sortable: false },
        ],
        footerProps: {
          'items-per-page-options': [10, 20, 30, 40],
          'items-per-page-text': 'Items por página:',
          'show-current-page': true,
          'show-first-last-page': true
        },
        itemsPerPage: 10,
        page: 1,
        totalElements: 0,
      },
      grupoSeleccionado: {},
      usuario: '',
      userRoles: {},
      roles: Role,
      rules: {
        required: (v) => !!v || "Campo obligatorio."
      },
      tituloDialogo: 'Agregar',
      dialogoCambiarEstado: false,
      mensajeEstado: '',
      listadoProcesosActivos: [],
      searchProcesosActivos: "",
      editar: false,
    }
  },
  mounted() {
    this.listarGrupoProceso();
    this.usuario = this.auth.username.toUpperCase();
    this.procesosActivos();
  },
  created() {
    this.userRoles = this.auth.roles;
  },
  computed: {
    ...mapState(["enterprise", "auth"]),
    formValid() {
      return this.crearGrupoProceso.nombre && this.crearGrupoProceso.proceso;
    }
  },
  watch: {
    'tablaGrupoProceso.page': function () {
      this.listarGrupoProceso();
    },
    'tablaGrupoProceso.itemsPerPage': function () {
      this.tablaGrupoProceso.page = 1;
      this.listarGrupoProceso();
    },
    'buscar.nombre': function () {
      this.tablaGrupoProceso.page = 1;
    },
    searchProcesosActivos: function () {
      this.procesosActivos(this.searchProcesosActivos);
    },
  },
  methods: {
    listarGrupoProceso() {
      this.tablaGrupoProceso.loading=true;
      this.$http.get(`msa-hr/api/grupo-proceso/listar`, {
        params: {
          page: this.tablaGrupoProceso.page - 1,
          size: this.tablaGrupoProceso.itemsPerPage,
          nombre: this.buscar.nombre,
          idEmpresa: this.enterprise.code
        }
      }).then((response) => {
        this.tablaGrupoProceso.items = response.data.content;
        this.tablaGrupoProceso.totalElements = response.data.totalElements;
        this.tablaGrupoProceso.loading=false;
      }).catch((error) => {
        console.error(error);
        this.tablaGrupoProceso.loading=false;
      })
    },
    abrirDialogoEditar(item) {
      this.editar = true;
      this.tituloDialogo = "Editar";
      this.dialogoGrupoProceso = true;
      this.crearGrupoProceso.nombre = item.nombre;
      this.crearGrupoProceso.proceso = item.proceso.idProceso;
      
      this.grupoSeleccionado = item;
    },
    editarGrupoProceso() {
      const grupo = {
        idGrupoProceso: this.grupoSeleccionado.idGrupoProceso,
        eliminado: this.grupoSeleccionado.eliminado,
        nombre: this.crearGrupoProceso.nombre,
        codStone: this.grupoSeleccionado.codStone,
        empresa: {
          idEmpresa: this.enterprise.code
        },
        lastmodifiedby: this.usuario,
        lastmodifieddate: new Date(),
        proceso: {
          idProceso: this.crearGrupoProceso.proceso
        }
      }
      this.$http.post(`msa-hr/api/grupo-proceso/guardar`, grupo)
        .then(() => {
          this.listarGrupoProceso();
          this.dialogoGrupoProceso = false;
          this.clear();
        }).catch((error) => {
          console.error(error);
        })
    },
    abrirDialogoEstado(item) {
      this.dialogoCambiarEstado = true
      this.grupoSeleccionado = item;
      this.mensajeEstado = this.grupoSeleccionado.eliminado === false ? '¿Desea inactivar la relación?' : '¿Desea activar la relación?';
      setTimeout(() => {
        this.mensajeEstado = this.grupoSeleccionado.eliminado === false ? '¿Desea inactivar la relación?' : '¿Desea activar la relación?';
      }, 1000);
    },
    async procesosActivos(nombreProceso) {
      const lista = await this.listar(nombreProceso, 'proceso', false)
      this.listadoProcesosActivos = lista.map(item => ({
        name: item.nombre.trim(),
        id: item.idProceso
      }))
    },
    async listar(nombre, Url, inactivo, proceso) {
      let lista = await this.$http.get(`msa-hr/api/${Url}/listar`, {
        params: {
          idEmpresa: this.enterprise.code,
          nombre,
          nombreCompleto: nombre,
          eliminado: !inactivo ? inactivo : '',
          proceso,
          size: 100
        },
      });
      return lista.data.content ? lista.data.content : [];
    },
    async agregarGrupoProceso() {

      let codigo;

      try{
        codigo = await this.generarCodigoStone()
        if(typeof codigo != "number" && codigo <1){
          throw new Error("No se pudo generar codStone")
        } 
      }catch (error) {
        console.error(error);
      }

      const grupo = {
        eliminado: false,
        nombre: this.crearGrupoProceso.nombre,
        codStone:codigo,
        empresa: {
          idEmpresa: this.enterprise.code
        },
        lastmodifiedby: this.usuario,
        lastmodifieddate: new Date(),
        proceso: {
          idProceso: this.crearGrupoProceso.proceso 
        }
      }
      this.$http.post(`msa-hr/api/grupo-proceso/guardar`, grupo)
        .then(() => {
          this.listarGrupoProceso();
          this.dialogoGrupoProceso = false;
          this.clear();
        }).catch((error) => {
          console.error(error);
        })
    },
    async generarCodigoStone(){
      return this.$http
        .get(`msa-hr/api/grupo-proceso/max-cod-stone`, {
          params: {
            idEmpresa:this.enterprise.code,
          },
        })
        .then((response) => {
          return response.data > 0 ? response.data + 1 : 1;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    cambiarEstado() {
      this.grupoSeleccionado.eliminado = this.grupoSeleccionado.eliminado == false ? true : false;
      const cambiarEstado = {
        idGrupoProceso: this.grupoSeleccionado.idGrupoProceso,
        eliminado: this.grupoSeleccionado.eliminado,
        nombre: this.grupoSeleccionado.nombre,
        codStone: this.grupoSeleccionado.codStone,
        empresa: {
          idEmpresa: this.enterprise.code
        },
        lastmodifiedby: this.usuario,
        lastmodifieddate: new Date(),
        proceso: {
          idProceso: this.grupoSeleccionado.proceso.idProceso
        }
      };
      this.$http
        .post(`msa-hr/api/grupo-proceso/guardar`, cambiarEstado)
        .then(() => {
          this.listarGrupoProceso();
          this.dialogoCambiarEstado = false;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    clear() {
      this.crearGrupoProceso.nombre = '';
      this.crearGrupoProceso.proceso = '';
      this.dialogoGrupoProceso = false;
      this.editar = false;
      this.tituloDialogo = "Agregar";
      if (this.$refs.formGrupoProceso) {
        this.$refs.formGrupoProceso.reset();
      }
    }
  },
}
</script>
<!-- #################################################################################### -->
<!-- ###### Sección de Style                                                       ###### -->
<!-- #################################################################################### -->
<style scoped>
.contenido {
  padding: 1rem;
  width: 100%;
  height: 85vh;
}

::v-deep .elevation div table thead tr th {
  background-color: rgb(223, 223, 223) !important;
}

::v-deep .elevation div table thead tr th span {
  font-weight: bold;
  color: black !important;
}

.encabezado {
  background-color: #1867c0;
  color: white;
}
</style>